<template>
  <div class="operationManage">
    <!-- <div> -->
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <el-tab-pane label="视频回放" name="first" class="video">视频回放</el-tab-pane>
      <el-tab-pane label="轨迹回放" name="second" class="track">
        <div class="left-list">
          <!-- 搜索框 -->
          <div class="search">
            <el-input v-model.trim="input" placeholder="车牌号码" clearable>
              <el-button slot="append" icon="el-icon-search" @click="search()"></el-button>
            </el-input>
          </div>
          <!-- 左侧树结构 -->
          <div
            class="tree"
            :style="{'max-height':treeHeight,height:treeHeight, 'overflow-y': 'scroll'}"
          >
            <el-tree
              :props="defaultProps"
              :load="loadNode"
              lazy
              node-key="uuid"
              :default-expanded-keys="[17]"
              @node-click="handleNodeClick"
              @node-expand="nodeExpand"
              accordion
            >
              <span slot-scope="{ node, data }">
                <!-- <i :class="data.icon" v-if="node.level===4"></i> -->
                <span v-if="node.level===1">{{data.name}}</span>
                <span v-else-if="node.level===2">{{data.cityName}}</span>
                <span v-else-if="node.level===3">{{data.province}}</span>
                <span v-else-if="node.level===4">{{data.companyName}}</span>
                <span v-else :class="'cartree'+data.onStatus">
                  <i></i>
                  {{data.carNum}}
                </span>
              </span>
            </el-tree>
          </div>
          <!-- 时间选择区域 -->
          <div class="selectTime">
            <el-form ref="Form" :model="Form" :rules="FormRules">
              <el-form-item label="日 期" prop="date">
                <el-date-picker
                  v-model="Form.date"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="select()">查询</el-button>
                <el-button @click="clear">清除条件</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="right-content">
          <div class="mapContainer" id="mapContainer"></div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- </div> -->
    <!-- <router-view /> -->
  </div>
</template>

<script>

export default {
  name: 'operationManage',
  components: {
  },
  data() {
    return {
      activeName: 'second',
      input: '',//搜索框内容
      defaultProps: {
        label: "name",
        children: [],
        isLeaf: "leaf"
      }, //树结构配置对象
      Form: {
        startTime: '',
        endTime: '',
        deviceSequence: ''
      },
      FormRules: {
        date: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ]
      },// 搜索表单校验规则
      centerlongitude: 39.984104,//地图中心经度, 
      centerlatitude: 116.307503,//地图中心纬度
      path: [
        // new TMap.LatLng(39.98481500648338, 116.30571126937866),
        // new TMap.LatLng(39.982266575222155, 116.30596876144409),
        // new TMap.LatLng(39.982348784165886, 116.3111400604248),
        // new TMap.LatLng(39.978813710266024, 116.3111400604248),
        // new TMap.LatLng(39.978813710266024, 116.31699800491333)
      ],
      markerList: [],
      polylineLayerList: [],
      polylineLayer: '',
      marker: ''
    }

  },
  computed: {
    treeHeight: function () {
      return (767.73 - 255) + 'px'
    }
  },
  created() {

  },
  mounted() {
    // 创建地图
    this.createMap(this.centerlongitude, this.centerlatitude)
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    search() {

    },
    // 点击左侧树节点触发
    async handleNodeClick(data, node) {
      if (node.level == 5) {
        this.Form.deviceSequence = data.simPhone
      }
			console.log(data.simPhone)
    },
    // 节点展开触发
    async nodeExpand(data, node, res) {
      if (node.level === 4) {

      }
    },
    // 获取省份--调试完成
    loadNode(node, resolve) {
      // 如果展开第一级节点，从后台加载一级节点列表
      if (node.level == 0) {
        this.getProvince(resolve)
      }
      // 如果展开其他级节点，动态从后台加载下一级节点列表
      if (node.level == 1) {
        this.getCity(node, resolve)
      }
      if (node.level == 2) {
        this.getDistrict(node, resolve)
      }
      if (node.level == 3) {
        this.getorderCompany(node, resolve)
      }
      if (node.level == 4) {
        this.getVehicleList(node, resolve)
      }
      if (node.level == 5) {
        resolve([])
      }
    },
    async getProvince(resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/area/selectProvinceList",
        {}
      );
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取省份失败");

      res.data.forEach(item => {
        item.name = item.province;
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
      });
      resolve(res.data);
    },
    // 获取市--调试完成
    async getCity(node, resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/area/selectCityList",
        {
          province: node.data.uuid
        }
      );
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取市失败");
      res.data.forEach(item => {
        item.name = item.cityName;
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
      });
      resolve(res.data);
    },
    // 获取区域--调试完成
    async getDistrict(node, resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/area/selectDistrictList",
        {
          cityId: node.data.uuid
        }
      );
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取区域失败");

      res.data.forEach(item => {
        item.name = item.disName;
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
      });
      resolve(res.data);
    },
    // 按地区获取机构--调试完成
    async getorderCompany(node, resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/tSysCompany/selectCompany",
        {
          district: node.data.uuid
        }
      );
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取机构失败");

      res.data.forEach(item => {
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
        item.name = item.companyName;
      });

      resolve(res.data);
    },
    //按机构获取车辆信息
    async getVehicleList(node, resolve) {
      this.corpId = node.data.uuid
      const { data: res } = await this.$http.post(
        '/pmService/tInfoVehicle/getTInfoVehicleList',
        {
          "corpId": node.data.uuid
        }
      )
      if (res.statusCode !== '200') { return this.$message.error('获取车辆信息失败') }
      res.data.forEach(item => {
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
        item.name = item.carNum
      })
      resolve(res.data)
    },
    async getSimpledVehicleList(node) {
      this.corpId = node.uuid;
      const { data: res } = await this.$http.post(
        "/pmService/tInfoVehicle/getTInfoVehicleList",
        {
          "corpId": node.uuid
        }
      );
      if (res.statusCode !== "200")
        return this.$message.error("获取车辆信息失败");
      // this.tabData = res.data;
      const list = res.data
      return list
      // this.total = +res.pageBean.pageDataCount;
    },
    // 点击查询按钮触发
    async select() {
      var that = this
      // 预校验
      // this.$refs.Form.validate(async valid => {
      // if (!valid) return
      // if (this.Form.deviceSequence === '') return this.$message.info('请先在左侧选择车辆')
      // if (this.markerList.length > 0) {
      //   this.markerList[0].setMap(null)
      //   this.markerList = []
      //   this.polylineLayerList[0].setMap(null)
      //   this.polylineLayerList = []
      //   this.add_marker(this.Form.deviceSequence)
      //   this.setZoom(that.path, 'mapp')
      //   return
      // } else {
      // 创建地图
      this.Form.startTime = Number(this.Form.date[0])
      this.Form.endTime = Number(this.Form.date[1])
      // }
      // 调接口获取历史点位
      // console.log(this.Form);
      const { data: res } = await this.$http.post('/baseInfo/position/queryHistoryPosition', JSON.parse(JSON.stringify(this.Form)))
      if (res.result !== 'true') return this.$message.error('获取历史点位失败')
      if (window.marker) {
        window.marker.setMap(null)
        window.marker = null
        window.polylineLayer.setMap(null)
        window.polylineLayer = null
      }
      console.log(res.data);
      if (res.data.length > 0) {

        this.path = []
        res.data.forEach(item => {
          var a = new TMap.LatLng(item.latitude, item.longitude)
          this.path.push(a)
        })
        window.mapp.setCenter(this.path[0])
        this.add_marker()
        // this.setZoom(that.path, 'mapp')
      } else {
        this.$message.error('未获取到历史GPS信息')
      }

      // }
      // })

    },
    clear() {
      this.$refs.Form.resetFields()
    },
    // 创建地图
    createMap(e1, e2) {
      var that = this
      //定义地图中心点坐标
      var center = new TMap.LatLng(e1, e2)
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      that.mapp = new TMap.Map('mapContainer', {
        center: center,//设置地图中心点坐标
        zoom: 15,   //设置地图缩放级别
        pitch: 30,  //设置俯仰角
        rotation: 20,    //设置地图旋转角度
        viewMode: '2D', // 设置地图视图模式
        // showControl: false,
        doubleClickZoom: false,
        // scrollable: false // 是否支持鼠标滚轮缩放地图
      })
      window.mapp = that.mapp
    },
    add_marker(v) {
      var that = this
      //创建mareker（小车）
      that.marker = new TMap.MultiMarker({
        id: 'marker',
        map: window.mapp,
        styles: { //样式设置
          'car-down': new TMap.MarkerStyle({
            'width': 40,  //小车图片宽度（像素）
            'height': 40, //高度
            'anchor': {   //图片中心的像素位置（小车会保持车头朝前，会以中心位置进行转向）
              x: 20, y: 20,
            },
            'faceTo': 'map',  //取’map’让小车贴于地面，faceTo取值说明请见下文图示
            'rotate': 180,    //初始小车朝向（正北0度，顺时针一周为360度，180为正南）
            'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/car.png',   //小车图片（图中小车车头向上，即正北0度）
          }),
          "start": new TMap.MarkerStyle({
            "width": 25,
            "height": 35,
            "anchor": { x: 16, y: 32 },
            "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png'
          }),
          "end": new TMap.MarkerStyle({
            "width": 25,
            "height": 35,
            "anchor": { x: 16, y: 32 },
            "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png'
          })
        },
        geometries: [{    //小车marker的位置信息
          id: 'car',      //因MultiMarker支持包含多个点标记，因此要给小车一个id
          styleId: 'car-down',    //绑定样式
          position: this.path[0],//初始坐标位置
        }
          , {
          "id": 'start',
          "styleId": 'start',
          "position": this.path[0]
        }, {
          "id": 'end',
          "styleId": 'end',
          "position": this.path[this.path.length - 1]
        }
        ]
      });
      // 创建线
      that.polylineLayer = new TMap.MultiPolyline({
        map: window.mapp,// 绘制到目标地图
        id: 'lineLayer',
        // 折线样式定义
        styles: {
          'style_blue': new TMap.PolylineStyle({
            'color': '#3777FF', //线填充色
            'width': 4, //折线宽度
            'borderWidth': 2, //边线宽度
            'borderColor': '#FFF', //边线颜色
            'lineCap': 'round' //线端头方式
          })
        },
        geometries: [{
          styleId: 'style_blue',
          paths: that.path
        }],
      });
      //调用moveAlong，实现小车移动
      that.marker.moveAlong({
        "car": {    //设置让"car"沿"path"移动，速度70公里/小时
          path: that.path,
          speed: 60
        }
      }, {
        autoRotation: true   //车头始终向前（沿路线自动旋转）
      }
      )
      that.markerList.push(that.marker)
      that.polylineLayerList.push(that.polylineLayer)
      window.marker = that.marker
      window.polylineLayer = that.polylineLayer
    },
    setZoom(points, v) {
      var that = this
      if (points.length > 1) {
        var maxLng = points[0].lat;
        var minLng = points[0].lng;
        var maxLat = points[0].lat;
        var minLat = points[0].lat;
        var res;
        for (var i = points.length - 1; i >= 0; i--) {
          res = points[i];
          if (res.lng > maxLng) maxLng = res.lng;
          if (res.lng < minLng) minLng = res.lng;
          if (res.lat > maxLat) maxLat = res.lat;
          if (res.lat < minLat) minLat = res.lat;
        };
        that.cenLng = (parseFloat(maxLng) + parseFloat(minLng)) / 2;
        that.cenLat = (parseFloat(maxLat) + parseFloat(minLat)) / 2;
        var zoom = that.getZoom(maxLng, minLng, maxLat, minLat, v);
      } else {
        if (points.length == 1) {
          window.mapp.setCenter(points[0]);
          window.mapp.setZoom(16);
        }
      }
    },
    //根据经纬极值计算绽放级别。
    async getZoom(maxLng, minLng, maxLat, minLat, v) {
      var zoom = ["50", "100", "200", "500", "1000", "2000", "5000", "10000", "20000", "25000", "50000", "100000",
        "200000", "500000", "1000000", "2000000"
      ] //级别18到3。
      // var pointA = new TMap.LatLng(maxLat, maxLng); // 创建点坐标A
      // var pointB = new TMap.Point(minLat, minLng); // 创建点坐标B
      // var distance = TMap.geometry.calculateDistance(pointA, pointB); //获取两点距离,保留小数点后两位
      var from = minLat + ',' + minLng;
      var to = maxLat + ',' + maxLng;
      const { data: res } = await this.$http.get("/userManageServer/hospital/selectDistance?from=" + from + "&to=" + to)
      // if (res.data.statusCode === 200) return this.$message.error('获取不到缩放比例')
      var that = this
      // if (data.statusCode===200) {
      var zoomLenth;
      var distance = res.data;
      for (var i = 0, zoomLen = zoom.length; i < zoomLen; i++) {
        if (zoom[i] - distance > 0) {
          zoomLenth = 18 - i + 3; //之所以会多3，是因为地图范围常常是比例尺距离的10倍以上。所以级别会增加3。
          break;
        }
      }
      that.centerAndZoom(new TMap.LatLng(that.cenLat, that.cenLng), zoomLenth, v);
    },
    centerAndZoom(point, zoom, v) {
      window.mapp.setCenter(point);
      window.mapp.setZoom(zoom);
    },
  }
}
</script>
<style lang="less" scoped>
.operationManage {
  width: 100%;
  height: 100%;
  //   display: flex;
  /deep/ .el-tabs {
    width: 100%;
    height: 100%;
    .el-tabs__header {
      margin: 0 !important;
      height: 40px;
      width: 100%;
      .el-tabs__nav-wrap {
        width: 100%;
        height: 100%;
        margin: 0 !important;
      }
    }
    .el-tabs__content {
      width: 100%;
      height: calc(100% - 40px);
      .video {
        width: 100%;
        height: 100%;
      }
      .track {
        width: 100%;
        height: 100%;
        display: flex;
        .left-list {
          position: relative;
          height: 100%;
          width: 440px;
          padding: 0 10px;
          // background-color: pink;
          padding-top: 10px;

          .search {
            height: 40px;
          }
          .tree {
            margin-top: 10px;
            // height: calc(100%-230px);
            /deep/.el-tree-node:focus > .el-tree-node__content {
              background-color: #ccc !important;
            }
            .el-tree {
              height: 100%;
              overflow: hidden;
              margin-top: 0.125rem;
              padding-left: 0.125rem;
              background-color: none;
              span {
                line-height: 0.325rem;
                font-size: 0.225rem;
                color: #000;
              }
              .cartree1,
              .cartree3,
              .cartree4,
              .cartree2 {
                display: flex;
                align-items: center;
                color: #0097fe;
                i {
                  display: inline-block;
                  width: 0.0625rem;
                  height: 0.0625rem;
                  background-color: #0097fe;
                  border-radius: 50%;
                  margin-right: 0.0625rem;
                }
              }
              .cartree2 {
                color: gray;
                i {
                  background-color: gray;
                }
              }
              .cartree3 {
                color: #0097fe;
                i {
                  background-color: red;
                }
              }
              .cartree4 {
                color: orange;
                i {
                  background-color: orange;
                }
              }
            }
          }
          /deep/ .selectTime {
            z-index: 9999999999999;
            // background-color: pink;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 160px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding-top: 20px;
            .el-form {
              width: 100%;
              height: 100%;
              padding: 0 10px;
              .el-form-item {
                width: 100%;
                height: 40px;
                margin-bottom: 20px;
                display: flex;
                justify-content: flex-end;
                .el-form-item__label {
                  width: 20%;
                  height: 40px;
                }
                .el-form-item__content {
                  .el-date-editor {
                    width: 100%;
                    height: 40px;
                    padding: 0;
                    .el-icon-time {
                      display: none;
                    }
                    .el-range-separator {
                      padding: 0;
                      margin: 0 5px;
                      line-height: 38px;
                    }
                  }
                }
              }
            }
          }
        }
        .right-content {
          height: 100%;
          width: 1580px;
          margin-top: 10px;
          #mapContainer {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
